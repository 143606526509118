export const namespace = 'employees';

import { createNamespacedHelpers } from 'vuex';
import axios from 'axios';
export const { mapGetters, mapActions } = createNamespacedHelpers(namespace);

const getters = {
    all: state => state.list,
    active: state => state.list.filter(f => !f.disabled),
    kkContacts: (state) => {
        let employees = {
            id: 'employees',
            name: Lang.get('sms-dialog.Ansatte'),
            email: '',
            mobile: '',
            persons: [],
            category: Lang.get('sms-dialog.Ansatte'),
            sortFirst: true,
        };

        state.list.forEach((employee) => {
            if (!employee.disabled) {
                employees.persons.push({
                    id: employee.id,
                    contactId: 0,
                    name: employee.name,
                    email: employee.name,
                    disabled: employee.disabled,
                    roles: employee.roles,
                    mobile: '',
                    type: 'employee',
                    profile_image: employee.profile_image,
                    category: Lang.get('sms-dialog.Ansatte'),
                });
            }
        });

        return employees;
    },
};

const LIST_SET = 'LIST_SET';
const ROLED_LIST_SET = 'ROLED_LIST_SET';
const mutations = {
    [LIST_SET]: (state, data) => {
        state.list = data;
    },
    [ROLED_LIST_SET]: (state, data) => {
        state.roledList = data;
    },
};

export const loadList = 'loadList';
export const loadRoledList = 'loadRoledList';
export const loadEmployeeWithRolesAndProjects = 'loadEmployeeWithRolesAndProjects';
export const loadListWithProfileImage = 'loadListWithProfileImage';
export const loadListWithRolesAndProfileImage = 'loadListWithRolesAndProfileImage';

const actions = {
    [loadList]: async ({ commit }, payload = null) => {
        // If we provide a default payload object we just commit this
        if (payload !== null && typeof payload.constructor === 'function') {
            commit(LIST_SET, payload);

            return;
        }

        const list = (await axios.get('/api/employees')).data;
        commit(LIST_SET, list);
    },
    [loadRoledList]: async ({ commit }) => {
        const list = (await axios.get('/api/employees?include[]=roles')).data;
        commit(LIST_SET, list);
        commit(ROLED_LIST_SET, list);
    },
    [loadEmployeeWithRolesAndProjects]: async ({ commit }) => {
        const list = (await axios.get('/api/employees?include[]=roles&include[]=projects')).data;
        commit(LIST_SET, list);
    },
    [loadListWithProfileImage]: async ({ commit }) => {
        const list = (await axios.get('/api/employees?include[]=profile_image')).data;
        commit(LIST_SET, list);
    },
    [loadListWithRolesAndProfileImage]: async ({ commit }) => {
        const list = (await axios.get('/api/employees?include[]=profile_image&include[]=roles')).data;
        commit(LIST_SET, list);
    },
};

const state = {
    list: [],
    roledList: [],
};

export const employees = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
